<template>
  <div class="mod-green_application_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ title }}</span>
          <span v-if="showHeaderButton">
            <el-button style="float: right; padding: 3px 3px" type="text" v-if="$hasPermission('miaoins')" @click="editorApplicationInformation()">编辑</el-button>
            <el-button style="float: right; padding: 3px 3px" type="text" v-if="$hasPermission('miaoins')" @click="saveApplicationInformation()">保存</el-button>
          </span>
          <!-- <span>{{ greenss }}</span> -->
        </div>
        <div class="basic_information">
          <el-form :inline="true" :disabled="disabled" :model="getGreenApplicationInformation" ref="getGreenApplicationInformation" :rules="dataRule" class="demo-form-inline">
            <el-form-item label="入住时间" prop="applyHospitalStartDate">
              <el-date-picker value-format="yyyy-MM-dd" v-model="getGreenApplicationInformation.applyHospitalStartDate" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="地区" prop="selectedOptions" v-if="showTCM">
              <el-cascader
                v-model="getGreenApplicationInformation.selectedOptions"
                :options="addressOptions"
             
                @change="handleChange"
                @expand-change="expandChange"
                @focus="focusChange"
                @blur="blurChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="地区" prop="selectedOptions" v-if="!showTCM">
              <el-cascader v-model="getGreenApplicationInformation.selectedOptions" :options="options" :props="{ expandTrigger: 'hover' }" @change="handleChange"></el-cascader>
            </el-form-item>
            <el-form-item label="服务方式">
              <!-- <el-input v-model="getGreenApplicationInformation.serveType" placeholder="服务方式"></el-input> -->
              <el-select v-model="getGreenApplicationInformation.serveType" placeholder="请选择服务方式" @change="serveTypeChange">
                <el-option v-for="item in serveTypeList" :key="item.id" :label="item.name" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="门店地址" v-if="showtherapyAddress">
              <el-select filterable clearable v-model="getGreenApplicationInformation.therapyAddress" placeholder="请选择门店地址">
                <el-option v-for="item in therapyAddressList" :key="item.id" :label="'('+item.name+')'+item.address" :value="'('+item.name+')'+item.address"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="服务地址" v-else>
              <el-input v-model="getGreenApplicationInformation.address" placeholder="服务地址"></el-input>
            </el-form-item>

            <el-form-item :label="appealDescLable">
              <el-input type="textarea" v-model="getGreenApplicationInformation.appealDesc" placeholder="诉求描述"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
import debounce from "lodash/debounce";
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      header_button: true,
      showHeaderButton: true,
      disabled: true,
      showAddress: false,
      showTCM: true,
      showtherapyAddress: false,
      applyTypeData: [
        { id: 1, name: "住院绿通" },
        { id: 2, name: "门诊绿通" },
        { id: 3, name: "院中陪护" },
      ],
      greenStatusOptions: [
        { id: 0, name: "初审中" },
        { id: 1, name: "初审不通过" },
        { id: 2, name: "需求确认" },
        { id: 3, name: "预约中" },
        { id: 4, name: "预约成功" },
        { id: 6, name: "结案" },
        { id: 7, name: "受理中" },
      ],
      serveTypeList: [
        { id: 1, name: "中医上门理疗", value: "door" },
        { id: 2, name: "线下门店理疗", value: "self" },
      ],
      therapyAddressList: [],
      applyHospitalData: [], // 医院
      applyDepartmentData: [], // 科室
      getGreenApplicationInformation: {
        id: "",
        greenNo: "",
        type: "",
        applyHospitalStartDate: "",
        applyHospitalEndDate: "",
        applyHospital: "",
        applyDepartment: "",
        doctorName: "",
        greenStatus: "",
        appealDesc: "",
        selectedOptions: [],
      },
      rightCode: "",
      ticketId: "",
      options: [],
      selectedOptions: [],
      title: "申请信息",
      appealDescLable: "诉求描述",
      showIllness: "",
      paramsList: {},
      rightId: "",
      provinceId: "",
      cityId: "",
      districtId: "",
      addressOptions: [],
      // addressOptions: [],
      addressOptions2: [],
    };
  },
  computed: {
    dataRule() {
      return {
        applyHospitalStartDate: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        applyHospitalEndDate: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        selectedOptions: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
      };
    },
  },
  components: {
    // AddOrUpdate
  },
  created() {
    this.addressOptions = regionData;
  },
  methods: {
    greenFun(params) {
      console.log("钟医生们", params);

      this.getGreenApplicationInformation = {
        ...this.getGreenApplicationInformation,
        ...params.entityDetails,
      };
      this.ticketId = params.id;
      let regionData = [];
      regionData.push(params.entityDetails.province);
      regionData.push(params.entityDetails.city);
      regionData.push(params.entityDetails.district);
      this.getGreenApplicationInformation.selectedOptions = regionData;
      console.log(regionData);
      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
      this.rightCode = params.rightCode;
      this.rightId = params.rightId;
      this.title = params.rightName;
      this.appealDescLable = "服务简述";
      if (params.entityDetails.serveType == "self") this.showTCM = true;
      else this.showTCM = false;
      console.log("options", this.options);

      if (params.rightCode === "ONSITE_PHYSICAL_THERAPY") {
        this.showIllness = false;
        // console.log('params.entityDetails.serveType',params.entityDetails.serveType)
        if (params.entityDetails.serveType == "self") {
          this.showtherapyAddress = true;
        } else {
          this.showtherapyAddress = false;
        }
      }
      // console.log("你好");
      let paramsList = {
        province: params.entityDetails.province,
        city: params.entityDetails.city,
        district: params.entityDetails.district,
      };
      console.log(paramsList);
      this.paramsList = paramsList;
      (this.provinceId = params.entityDetails.province), (this.cityId = params.entityDetails.city), (this.districtId = params.entityDetails.district), this.getTherapyList();
      let paramsObj = {
        rightId: this.rightId,
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
      };
      this.getAreaList();
      this.getAddressList(paramsObj);
    },
      getAreaList() {
      this.$http
        .get(`/sys/region/tree`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let dataList = res.data;
          for (let i in dataList) {
            dataList[i].value = dataList[i].id;
            dataList[i].label = dataList[i].name;
          }

          let dataList2 = treeDataTranslate(dataList);
          this.options = dataList2
        })
        .catch(() => {});
    },
    // 编辑
    editorApplicationInformation() {
      this.header_button = false;
      this.disabled = false;
      this.$emit("fatherMethod");
    },
    getAddressList(params) {
      let options = this.options;

      this.$http
        .post(`/health/healthaddress/getTherapyList`, params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          // console.log('地址',res)
          let data = res.data;
          let TherapyList = [];
          for (let i in data) {
            let addressObj = {};
            addressObj.address = data[i].address;
            addressObj.addressId = data[i].addressId;
            addressObj.name = data[i].name;
            TherapyList.push(addressObj);
          }

          this.therapyAddressList = TherapyList;
        })
        .catch(() => {});
    },
    getAddressList2(e) {
      let paramsObj = {
        rightId: this.rightId,
        provinceId: e.provinceId,
        cityId: e.cityId,
        districtId: e.districtId,
      };
      // this.addressOptions = [];
      // this.addressOptions = [...addressOptions2]
      this.$http
        .post(`/health/healthaddress/getTherapyList`, paramsObj)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          console.log("地址", res);
          // console.log("地址", this.addressOptions);
          let data = res.data;
          let addressOptions = [...this.addressOptions2];
          let TherapyList = [];
          for (let i in data) {
            if (data[i].provinceId !== null) {
              let provinceObj = {};
              provinceObj.label = data[i].provinceName;
              provinceObj.value = data[i].provinceId;
              provinceObj.children = [];
              addressOptions.push(provinceObj);
            }
            if (data[i].cityId !== null) {
              for (let j in addressOptions) {
                if (addressOptions[j].value == e.provinceId) {
                  // console.log('我执行了',addressOptions)
                  let cityObj = {};
                  cityObj.label = data[i].cityName;
                  cityObj.value = data[i].cityId;
                  cityObj.children = [];
                  addressOptions[j].children.push(cityObj);
                  addressOptions[j].children =  addressOptions[j].children.filter((x, index, self) => {
                    var arrids = [];
                     addressOptions[j].children.forEach((item, i) => {
                      arrids.push(item.value);
                    });
                    return arrids.indexOf(x.value) === index;
                  });
                }
              }
            }
            if (data[i].districtId !== null) {
              for (let j in addressOptions) {
                let cityArr = addressOptions[j].children;
                for (let k in cityArr) {
                  if (cityArr[k].value == e.cityId) {
                    let areaObj = {};
                    areaObj.label = data[i].districtName;
                    areaObj.value = data[i].districtId;
                    cityArr[k].children.push(areaObj);
                    cityArr[k].children =  cityArr[k].children.filter((x, index, self) => {
                    var arrids = [];
                     cityArr[k].children.forEach((item, i) => {
                      arrids.push(item.value);
                    });
                    return arrids.indexOf(x.value) === index;
                  });
                  }
                }
                addressOptions[j].children = cityArr;
                
              }
            }
            if (data[i].addressId !== null) {
              let addressObj = {};
              addressObj.address = data[i].address;
              addressObj.addressId = data[i].addressId;
              addressObj.name = data[i].name;
              TherapyList.push(addressObj);
            }
          }
          this.addressOptions = [...addressOptions];
          // console.log('data',this.addressOptions)
          this.addressOptions2 = [...addressOptions];
          this.therapyAddressList = [...TherapyList];
        })
        .catch(() => {});
    },
    // // 获取线下门店地址
    getTherapyList() {
      let params = this.paramsList;
      this.$http
        .get(`/sys/systherapyregion/getTherapyList`, { params })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.therapyAddressList = res.data;
        })
        .catch(() => {});
    },
    // 保存
    saveApplicationInformation() {
      if (!this.getGreenApplicationInformation.appealDesc) {
        return this.$message.error("需求描述不能为空!");
      }

      this.getGreenApplicationInformation.ticketId = Number(this.ticketId);
      this.$http
        .post(`/healthbusiness/health-green-channel/update`, this.getGreenApplicationInformation)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: "保存成功",
            type: "success",
            duration: 500,
            onClose: () => {
              this.visible = false;
              this.header_button = true;
              this.disabled = true;
              // this.getGreenApplicationInformations();
            },
          });
        })
        .catch(() => {});
      // });
    },
    // handleChange(e) {
    //   this.getGreenApplicationInformation.province = e[0];
    //   this.getGreenApplicationInformation.city = e[1];
    //   this.getGreenApplicationInformation.district = e[2];
    //   let paramsList = {
    //     province: e[0],
    //     city: e[1],
    //     district: e[3],
    //   };
    //   this.paramsList = paramsList;
    //   this.getTherapyList();
    // },
    handleChange(e) {
      console.log(e)
      this.getGreenApplicationInformation.province = e[0];
      this.getGreenApplicationInformation.city = e[1];
      this.getGreenApplicationInformation.district = e[2];
      let paramsList = {
        provinceId: e[0],
        cityId: e[1],
        districtId: e[2],
      };
      this.getAddressList2(paramsList);
    },
    expandChange(e) {
      let params2 = {
        provinceId: "",
        cityId: "",
        districtId: "",
      };
      if (e[0]) {
        params2.provinceId = e[0];
      }
      if (e[1]) {
        params2.cityId = e[1];
      }
      if (e[2]) {
        params2.districtId = e[2];
      }
      this.getAddressList2(params2);
    },
    focusChange() {
      let params2 = {
        provinceId: "",
        cityId: "",
        districtId: "",
      };
      this.getAddressList2(params2);
    },
    blurChange() {
      // this.addressOptions = regionData;
    },
    serveTypeChange(e) {
      if (e == "door") {
        this.showtherapyAddress = false;
        this.showTCM = false;
      } else {
        this.showtherapyAddress = true;
        this.showTCM = true;
      }
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>
<style>
/deep/.el-input {
  color: #000 !;
}
/deep/.el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>
