<template>
  <div class="mod-green_application_information}">
    <div>
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ title }}影像件</span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <div class="uploadImageContainer">
          <div class="uploadImageContainer_left">
            <div>
              <h3>被保险人身份证明：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="insuredImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successHandleInsured"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div class="inspection_report">
              <h3>检查报告：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="reportImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successHandleReport"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
          </div>
          <div class="vertical_border"></div>
          <div class="uploadImageContainer_right">
            <div>
              <h3>入院通知单和诊断证明：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="CasesImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successHandleCases"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div class="inspection_report">
              <h3>其他辅助材料：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="otherImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successHandleOtherImg"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: '',
      num: 0,
      insuredImgList: [], // 被保险人身份图片回显列表
      CasesImgList: [], // 入院通知单和诊断证明
      reportImgList: [], // 检查报告
      otherImgList: [], // 被保险人身份图片回显列表
      dialogVisibleInsured: false, // 大图显示
      dialogInsuredImageUrl: '', // 大图显示URL
      imgDataObj: {},
      dialogImageUrl: '',
      dialogVisible: false,
      status: '',
      title: '',
      disabled: false,
    };
  },
  components: {},
  created() {
    this.init();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.url = `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`;
      this.num = 0;
    },
    // 父组件传参
    greenFun(params) {
      let insuredImgList = [];
      let CasesImgList = [];
      let otherImgList = [];
      let reportImgList = [];
      this.status = params.status;
      if (params.status === 2 || params.status === 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      let fileList = params.entityDetails.healthBusinessFileList;
      for (let i in fileList) {
        let imageObj = {
          uid: fileList[i].id,
          url: fileList[i].fileUrl,
          greenAdvanceId: fileList[i].greenAdvanceId,
          fileName: fileList[i].fileName,
          fileType: fileList[i].fileType,
          fileUrl: fileList[i].fileUrl,
          type: fileList[i].type,
        };
        if (fileList[i].fileType === 1) {
          insuredImgList.push(imageObj);
        } else if (fileList[i].fileType === 2) {
          CasesImgList.push(imageObj);
        } else if (fileList[i].fileType === 3) {
          otherImgList.push(imageObj);
        } else if (fileList[i].fileType === 4) {
          reportImgList.push(imageObj);
        }
      }
      this.insuredImgList = insuredImgList;
      this.CasesImgList = CasesImgList;
      this.otherImgList = otherImgList;
      this.reportImgList = reportImgList;
      if (params.rightCode === 'SECONDARY_SERIOUS_DISEASE') {
        // this.showAddress = true;
        this.rightCode = 'SECONDARY_SERIOUS_DISEASE';
        this.title = '重疾二次';
        // this.showIllness = false;
      } else {
        this.title = '协助出院';
        // this.showIllness = true;
        this.rightCode = 'ASSIST_LEAVE_HOSPITAL';
      }
    },
    // 上传之前
    beforeUploadHandle(file) {
      var FileExt = file.name.replace(/.+\./, '');
      if (
        ['jpg', 'jpeg', 'png', 'gif', 'pdf'].indexOf(FileExt.toLowerCase()) ===
        -1
      ) {
        this.$message({
          type: 'warning',
          // message: '请上传后缀名为jpg、png、txt、pdf、doc、docx、xlsx、zip或rar的附件！'
          message: '请上传后缀名为jpg、png、pdf的附件！',
        });
        return false;
      }
      this.num++;
    },
    // 被保险人身份证明上传成功
    successHandleInsured(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 1;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 检查报告：
    successHandleReport(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 4;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 入院通知单和诊断证明：
    successHandleCases(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 2;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 其他辅助材料
    successHandleOtherImg(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 3;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.fileList = fileList;
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    insertGreenFile() {
      this.$http
        .post(
          `/healthbusiness/health-business-file/insertFile`,
          this.imgDataObj
        )
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let imageObj = {
            uid: res.data.id,
            url: res.data.fileUrl,
            greenAdvanceId: res.data.greenAdvanceId,
            fileName: res.data.fileName,
            fileType: res.data.fileType,
            fileUrl: res.data.fileUrl,
            type: res.data.type,
          };
          if (res.data.fileType === 1) {
            this.insuredImgList.push(imageObj);
          } else if (res.data.fileType === 2) {
            this.CasesImgList.push(imageObj);
          } else if (res.data.fileType === 3) {
            this.otherImgList.push(imageObj);
          } else if (res.data.fileType === 4) {
            this.reportImgList.push(imageObj);
          }
          this.$message({
            message: '上传成功',
            type: 'success',
            duration: 500,
            onClose: () => {},
          });
        })
        .catch(() => {});
    },
    handleRemove(file, fileList) {
      if (this.status === 2) {
        return this.$message.error('服务已完成，无法删除！');
      }
      this.$http
        .get(`/healthbusiness/health-business-file/delete`, {
          params: { id: file.uid },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          if (file.fileType === 1) {
            this.insuredImgList.splice(
              this.insuredImgList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 2) {
            this.CasesImgList.splice(
              this.CasesImgList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 3) {
            this.otherImgList.splice(
              this.otherImgList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 4) {
            this.reportImgList.splice(
              this.reportImgList.findIndex((item) => item.id === file.uid),
              1
            );
          }
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 500,
            onClose: () => {},
          });
        })
        .catch(() => {});
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 被保险人身份证明
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.uploadImageContainer {
  display: flex;
  margin-bottom: 20px;
}
.uploadImageContainer_left {
  flex: 50%;
}
.uploadImageContainer_right {
  flex: 50%;
}
.vertical_border {
  border-left: 1px solid #ccc;
}
.inspection_report {
  margin-top: 30px;
}
.uploadImageContainer_right > div {
  margin-left: 30px;
}
h3 {
  color: rgb(114, 113, 113);
}
</style>
